import { InputAdornment, TextField } from "@mui/material";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";

type NewInputType = {
    value: number | null | undefined;
    setOutterValue: Function;
    validateOnChange: Function;
}
const NewInput = forwardRef(({ value, setOutterValue, validateOnChange }: NewInputType, ref) => {
    const initValue = useMemo(() => {
        if (value !== null && value !== undefined) {
            return (value * 100).toFixed(2)
        }
        else {
            return "";
        }
    }, [value]);
    const [innerValue, setInnerValue] = useState(() => {
        return initValue.toString()
    });

    const [isValueValid, setIsValueValid] = useState(true);

    useImperativeHandle(
        ref,
        () => ({
            setError: (value: boolean) => {
                setIsValueValid(value);
            },
        }),
        [],
    )

    useEffect(() => {
        validateOnChange(initValue);
    }, [initValue, validateOnChange]);


    const onChangeHandler = useCallback((e) => {
        const strValue = e.target.value;
        let prepared = parseFloat(parseFloat(strValue).toFixed(2)) / 100;
        if (isNaN(prepared)) {
            validateOnChange(prepared);
            setInnerValue("");
            setOutterValue(prepared);
            return;
        }
        if (!(prepared > 0)) {
            setInnerValue("")
        }
        setInnerValue(strValue);
        validateOnChange(prepared);
        setOutterValue(prepared);
    }, [setOutterValue, validateOnChange]);

    const onBlurHandler = useCallback(() => {
        setInnerValue((prev: any) => {
            let prepared: string | number = parseFloat(prev) / 100;
            prepared = prepared.toFixed(5);
            prepared = (parseFloat(prepared) * 100)
            prepared = prepared.toFixed(2);
            return prepared;
        })

    }, [])

    return (<TextField sx={{ fontSize: "0.7vw", pt: 1, width: 100 }}
        onBlur={onBlurHandler}
        fullWidth={false}
        error={!isValueValid}
        size="small" type="text" InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }} value={innerValue} onChange={onChangeHandler} />)
})


export default NewInput;