import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from "react";
import NewInput from "./NewInput";

type Props = {
    label: string;
    management: Function;
    value: number | undefined;
};

const FeesAndThreshold = forwardRef(
    ({ management, value, label }: Props, ref) => {
        const [valueIsValid, setValueIsValid] = useState(true);
        const validate = useCallback((ot) => {
            if (typeof ot === "string") {
                if (ot?.length > 0) {
                    let _ot = parseFloat(ot);
                    if (_ot < 0 /*|| _ot > 2*/) {
                        setValueIsValid(false);
                        return false;
                    } else {
                        setValueIsValid(true);
                        return true;
                    }
                } else {
                    setValueIsValid(false);
                    return false;
                }
            } else if (typeof ot === "number") {
                if (isNaN(ot)) {
                    setValueIsValid(false);
                    return false;
                }
                if (ot < 0 /*|| _ot > 2*/) {
                    setValueIsValid(false);
                    return false;
                } else {
                    setValueIsValid(true);
                    return true;
                }
            } else {
                return false
            }

        }, []);



        useImperativeHandle(
            ref,
            () => ({
                validate: (value: any) => {
                    const isValid = validate(value);
                    setValueIsValid(isValid);
                    return isValid;
                },
            }),
            [validate]
        );

        const inputRef: any = useRef(null);

        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.setError(valueIsValid);
            }
        }, [valueIsValid])

        return (
            <Box
                display={"flex"}
                gap={1}
                alignItems={"center"}
                width={"100%"}
                justifyContent={"space-between"}
            >
                <Box display={"flex"}>
                    <Typography sx={{ lineHeight: 1 }} variant="subtitle1">
                        {label}
                    </Typography>
                </Box>
                <NewInput ref={inputRef} value={value} setOutterValue={management} validateOnChange={validate} />
            </Box>
        );
    }
);

export default FeesAndThreshold;



