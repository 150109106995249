import { Box, Card, CardContent, Divider } from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useImmerReducer } from "use-immer";
import { Strategies } from "../../../../../../api/compute/Strategies";
import { SystematicProducts } from "../../../../../../api/compute/SystematicProducts";
import { deepClone } from "../../../../../../deepClone";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { TDate } from "../../../../../../trendrating/date/TDate";
import { createContenReducer, initState } from "./ CreateContentReducer";
import BasicInfo from "./cards/BasicInfo";
import FeesAndThreshold from "./cards/FeesAndThreshold";
import PriceLevel from "./cards/PriceLevel";
import TodaysPortfolio from "./cards/TodaysPortfolio";
import UpcomingRebalances from "./cards/UpcomingRebalances";

type CreateContentReactType = {
  lists: any;
  strategies: any;
};

const CreateContentReact = forwardRef(
  ({ lists, strategies }: CreateContentReactType, ref) => {
    const [state, dispatch] = useImmerReducer(createContenReducer, initState);
    const [systematicProductsNames, setsystematicProductsNames] = useState<
      string[]
    >([]);
    const _lists = useMemo(() => lists, [lists]);
    const _strategies = useMemo(() => strategies, [strategies]);
    const basicInfoRef = useRef<any>(null);
    const priceLevelRef = useRef<any>(null);
    const TodaysPortfolioRef = useRef<any>(null);
    const feeRef = useRef<any>(null);
    const orderThresholdRef = useRef<any>(null);
    const environment = useEnvironment();
    const strategyAPI = useMemo(
      () => new Strategies(environment.get("setup")),
      [environment]
    );
    const smsAPI = useMemo(
      () => new SystematicProducts(environment.get("setup")),
      [environment]
    );

    const retriveSystematicProductsNames = useCallback(async () => {
      const response: any = await smsAPI.getList(["name"]);

      if (response && response.length) {
        setsystematicProductsNames(response.map((sms) => sms.name));
      }
    }, [smsAPI]);

    const onComponentMount = useCallback(() => {
      retriveSystematicProductsNames();
    }, [retriveSystematicProductsNames]);

    useEffect(() => {
      onComponentMount();
    }, [onComponentMount]);

    const validateNameUniqueness = useCallback(
      (name: string) => {
        let isUnique = true;
        for (let i = 0; i < systematicProductsNames.length; i++) {
          if (name === systematicProductsNames[i]) {
            isUnique = false;
            break;
          }
        }
        basicInfoRef?.current?.validationNameUniqueness(isUnique);
        return isUnique;
      },
      [systematicProductsNames]
    );
    const validateName = useCallback((name: string | null) => {
      if (name != null) {
        if (name.length > 0) {
          basicInfoRef?.current?.validationName(true);
          return true;
        }
      }
      basicInfoRef?.current?.validationName(false);
      return false;
    }, []);
    const validateStrategy = useCallback((strategyID: number | null) => {
      if (strategyID != null) {
        basicInfoRef?.current?.validationStrategy(true);
        return true;
      } else {
        basicInfoRef?.current?.validationStrategy(false);
        return false;
      }
    }, []);
    const validateFee = useCallback((fee: null | number) => {
      if (fee != null) {
        feeRef.current.validate(true);
        return true;
      } else {
        feeRef.current.validate(false);
        return false;
      }
    }, []);

    const validateOrderThreshold = useCallback(
      (orderThreshold: null | number) => {
        if (orderThresholdRef?.current) {
          const isValid =
            orderThresholdRef?.current.validate(orderThreshold);
          return isValid;
        }
      },
      []
    );
    const validateInheritedBacktesting = useCallback(() => {
      if (state.priceLevel.fromDate === true) {
        if (
          state.priceLevel.isBacktestingInheritedDate != null &&
          state.priceLevel.isBacktestingInherited
        ) {
          priceLevelRef.current.validateDate(true);
          return true;
        } else {
          priceLevelRef.current.validateDate(false);
          return false;
        }
      }
      priceLevelRef.current.validateDate(true);
      return true;
    }, [
      state.priceLevel.fromDate,
      state.priceLevel.isBacktestingInherited,
      state.priceLevel.isBacktestingInheritedDate,
    ]);
    const validateTodaysPortfolio = useCallback(() => {
      let isValid: boolean = true;
      if (state.todayList.list != null) {
        if (state.todayList.list.id == null) {
          isValid = false;
        }
      }
      TodaysPortfolioRef.current.validate(isValid);
      return isValid;
    }, [state.todayList.list]);
    const nameHandler = useCallback(
      (event) => {
        let name: string = event.target.value;
        validateNameUniqueness(name);
        if (validateName(name)) {
          dispatch({ type: "SET_NAME", value: name });
        } else {
          dispatch({ type: "SET_NAME", value: null });
        }
      },
      [dispatch, validateName, validateNameUniqueness]
    );
    const managementFeesHandler = useCallback(
      (fee) => {
        if (fee > 0) {
          validateFee(fee);
          dispatch({ type: "SET_FEES", value: fee });
        } else {
          validateFee(null);
          dispatch({ type: "SET_FEES", value: null });
        }
      },
      [dispatch, validateFee]
    );

    const managementOrderThresholdHandler = useCallback(
      (value) => {
        if (value > 0) {
          validateOrderThreshold(value);
          dispatch({ type: "SET_ORDER_THRESHOLD", value: value });
        } else {
          validateOrderThreshold(null);
          dispatch({ type: "SET_ORDER_THRESHOLD", value: null });
        }
      },
      [dispatch, validateOrderThreshold]
    );
    const strategyHandler = useCallback(
      async (event) => {
        const strategyID: number = event.target.value;
        const response = await strategyAPI.getById(strategyID);
        const currency = response.params.strategy.currency;
        const benchmark = response.params.strategy.benchmark;
        dispatch({
          type: "SET_STRATEGY",
          value: { strategyID, currency, benchmark },
        });
        basicInfoRef?.current?.validationStrategy(true);
      },
      [dispatch, strategyAPI]
    );
    const initValueHandler = useCallback(
      (event) => {
        let value: number = parseInt(event.target.value);
        dispatch({ type: "SET_INIT_VALUE", value: value });
      },
      [dispatch]
    );
    const changeDataHandler = useCallback(
      (date) => {
        if (date != null) {
          const days = TDate.dateToDays(new Date(date));
          dispatch({ type: "SET_DATE", value: days });
          priceLevelRef.current.validateDate(true);

          return;
        }
        dispatch({ type: "SET_DATE", value: null });

        return;
      },
      [dispatch]
    );
    const upcomingRebalancesHandler = useCallback(
      (e) => {
        dispatch({ type: "SET_AUTOREBALANCE", value: e.target.checked });
      },
      [dispatch]
    );
    const inheritBacktestingHandler = useCallback(
      (radio: "yes" | "no" | "yesFrom") => {
        if (radio === "yes") {
          dispatch({ type: "SET_IS_FROM_DATE", value: false });
          dispatch({ type: "SET_IS_BACKTESTING_INHERIT", value: true });
        } else if (radio === "yesFrom") {
          dispatch({ type: "SET_IS_FROM_DATE", value: true });
          dispatch({ type: "SET_IS_BACKTESTING_INHERIT", value: true });
        } else {
          dispatch({ type: "SET_IS_FROM_DATE", value: false });
          dispatch({ type: "SET_IS_BACKTESTING_INHERIT", value: false });
        }
      },
      [dispatch]
    );
    const todaysPortfolioHandler = useCallback(
      (value: { radio: "cash" | "strategy" | "list"; value: any }) => {
        switch (value.radio) {
          case "cash":
            dispatch({ type: "SET_TODAYLISTS_CASH", value: value.value });
            break;
          case "strategy":
            dispatch({ type: "SET_TODAYLISTS_STRATEGY", value: value.value });
            break;
          case "list":
            dispatch({ type: "SET_TODAYLISTS_LIST", value: value.value });
            break;
        }
      },
      [dispatch]
    );
    const benchmarkHandler = useCallback(
      (benchmarkValue) =>
        dispatch({ type: "SET_BENCHMARK", value: benchmarkValue }),
      [dispatch]
    );
    const currencyHandler = useCallback(
      (currencyValue) =>
        dispatch({ type: "SET_CURRENCY", value: currencyValue }),
      [dispatch]
    );

    useImperativeHandle(
      ref,
      () => ({
        getState: () => {
          let isDataValid: boolean = true;

          if (!validateName(state.basic.name)) {
            isDataValid = false;
          } else {
            if (state?.basic?.name) {
              if (!validateNameUniqueness(state?.basic?.name)) {
                isDataValid = false;
              }
            }
          }
          if (!validateStrategy(state.basic.strategyId)) {
            isDataValid = false;
          }
          if (!validateFee(state.basic.expenseRatio)) {
            isDataValid = false;
          }

          let ot = state.basic.orderThreshold?.toString();
          if (!ot) {
            isDataValid = false;
            validateOrderThreshold(ot);
          } else {
            isDataValid = validateOrderThreshold(ot);
          }

          if (!validateInheritedBacktesting()) {
            isDataValid = false;
          }
          if (!state.priceLevel.isBacktestingInherited) {
            if (!validateTodaysPortfolio()) {
              isDataValid = false;
            }
          }
          if (isDataValid) {
            return deepClone(state);
          }
          return null;
        },
      }),
      [
        state,
        validateFee,
        validateInheritedBacktesting,
        validateName,
        validateNameUniqueness,
        validateOrderThreshold,
        validateStrategy,
        validateTodaysPortfolio,
      ]
    );
    return (
      <Box display={"flex"} gap={2} width={"100%"} flexDirection={"column"}>
        <Box display={"flex"}>
          <BasicInfo
            ref={basicInfoRef}
            strategyHandler={strategyHandler}
            nameHandler={nameHandler}
            strategies={_strategies}
            state={state}
            benchmarkHandler={benchmarkHandler}
            currencyHandler={currencyHandler}
          />
        </Box>
        <Box display={"flex"} gap={2}>
          <UpcomingRebalances
            value={state.basic.autoRebalance}
            handler={upcomingRebalancesHandler}
          />

          <Card sx={{ flex: 1, boxShadow: 3 }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >

              <FeesAndThreshold
                ref={feeRef}
                label={"Management Fees"}
                management={
                  managementFeesHandler
                }
                value={state.basic.expenseRatio}
              />

              {/* <Fees
                ref={feeRef}
                managementFeesHandler={managementFeesHandler}
                expenseRatio={state.basic.expenseRatio}
              /> */}
              <Divider
                sx={{ width: "100%", display: "block", m: 0 }}
                variant="middle"
              />

              <FeesAndThreshold
                ref={orderThresholdRef}
                label={"Order threshold"}
                management={
                  managementOrderThresholdHandler
                }
                value={state.basic.orderThreshold}
              />

              {/* <OrderThreshold
                ref={orderThresholdRef}
                managementOrderThresholdHandler={
                  managementOrderThresholdHandler
                }
                orderThreshold={state.basic.orderThreshold}
              /> */}
            </CardContent>
          </Card>
        </Box>
        <Box display={"flex"} gap={2}>
          <PriceLevel
            ref={priceLevelRef}
            changeDataHandler={changeDataHandler}
            state={state.priceLevel}
            initValueHandler={initValueHandler}
            inheritBacktestingHandler={inheritBacktestingHandler}
          />
          {!state.priceLevel.isBacktestingInherited && (
            <TodaysPortfolio
              ref={TodaysPortfolioRef}
              todaysPortfolioHandler={todaysPortfolioHandler}
              state={state}
              lists={_lists}
            />
          )}
        </Box>
      </Box>
    );
  }
);

export default CreateContentReact;
